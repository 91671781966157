import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../../AppConst';

const initialState = {
  subscriptions: [],
  selectedSubscription: null,
  loading: false,
  error: null,
};

export const subscriptionSlice = createSlice({
  name: 'subscriptionReducer',
  initialState,
  reducers: {
    getSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
      state.loading = false;
      state.error = null;
    },
    selectSubscription: (state, action) => {
      state.selectedSubscription = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { getSubscriptions, selectSubscription, setLoading, setError } =
  subscriptionSlice.actions;

// Function to add or update a Subscription
export const addOrUpdateSubscription = (subscriptionData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    if (subscriptionData.SubscriptionId) {
      subscriptionData.OperationType = 'U';
    } else {
      subscriptionData.OperationType = 'I';
    }
    const response = await axios.post(
      `${baseUrl}/subscription/AddUpdate`,
      subscriptionData,
      config,
    );
    if (response.status === 200 || response.data.success === true) {
      dispatch(getSubscriptions(response.data.data));
      return Promise.resolve(response.data.data); // Resolve the promise for successful add/update
    }
    dispatch(setError('Failed to add/update Subscription.'));
    return Promise.reject(new Error('Failed to add/update Subscription.')); // Reject the promise with an Error object for failed add/update
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

// Function to fetch Subscription by ID
export const fetchSubscriptionById = (SubscriptionId) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(`${baseUrl}/subscription/${SubscriptionId}`, config);
    if (response.status === 200) {
      dispatch(selectSubscription(response.data.data)); // Assuming you receive single Subscription data here
      return Promise.resolve(response.data.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to fetch Subscription details.'));
    return Promise.reject(new Error('Failed to fetch Subscription details.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

// Function to fetch all companies
export const fetchSubscriptions = () => async (dispatch) => {
  dispatch(setLoading());

  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await axios.get(`${baseUrl}/subscription/All`, config);
    if (response.status === 200) {
      dispatch(getSubscriptions(response.data.data)); // Assuming you receive list of companies here
      return Promise.resolve(response.data.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to fetch companies list.'));
    return Promise.reject(new Error('Failed to fetch companies list.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

export const deleteSubscriptions = (SubscriptionId) => async (dispatch) => {
  dispatch(setLoading());

  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    
    const response = await axios.delete(`${baseUrl}/subscription/${SubscriptionId}`, config);
    if (response.status === 200) {
      dispatch(getSubscriptions(response.data.data)); // Assuming you receive list of companies here
      return Promise.resolve(response.data.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to delete subscripations.'));
    return Promise.reject(new Error('Failed to delete subscripations.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

export default subscriptionSlice.reducer;
