import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../../AppConst';

const initialState = {
  companies: [],
  selectedCompany: null,
  loading: false,
  error: null,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    getCompanies: (state, action) => {
      state.companies = action.payload;
      state.loading = false;
      state.error = null;
    },
    selectCompany: (state, action) => {
      state.selectedCompany = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { getCompanies, selectCompany, setLoading, setError } = companySlice.actions;

// Function to add or update a company
export const addOrUpdateCompany = (companyData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    if (companyData.companyId) {
      companyData.OperationType = 'U';
    } else {
      companyData.OperationType = 'I';
    }
    const response = await axios.post(`${baseUrl}/company/AddUpdate`, companyData, config);
    if (response.status === 200 || response.data.success === true) {
      // dispatch(getCompanies(response.data)); // Assuming you receive updated list of companies after add/update
      return Promise.resolve(response.data); // Resolve the promise for successful add/update
    }
    dispatch(setError('Failed to add/update company.'));
    return Promise.reject(new Error('Failed to add/update company.')); // Reject the promise with an Error object for failed add/update
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

// Function to fetch company by ID
export const fetchCompanyById = (companyId) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(`${baseUrl}/company/${companyId}`, config);
    if (response.status === 200) {
      dispatch(selectCompany(response.data)); // Assuming you receive single company data here
      return Promise.resolve(response.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to fetch company details.'));
    return Promise.reject(new Error('Failed to fetch company details.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

// Function to fetch all companies
export const fetchCompanies = () => async (dispatch) => {
  dispatch(setLoading());

  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await axios.get(`${baseUrl}/company/All`, config);
    if (response.status === 200) {
      console.log('ResponseData', response.data);
      //  dispatch(getCompanies(response.data)); // Assuming you receive list of companies here
      return Promise.resolve(response.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to fetch companies list.'));
    return Promise.reject(new Error('Failed to fetch companies list.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

export default companySlice.reducer;
