import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import CustomizerReducer from './customizer/CustomizerSlice';
import AuthReducer from './apps/auth/AuthSlice';
import CompanyReducer from './apps/company/CompanySlice'
import CommonContextReducer from './apps/commonContext/CommonContextSlice'
import SubscriptionReducer from './apps/subscription/SubscriptionSlice';
import AccessControlReducer from './apps/accessControl/AccessControlSlice';
import FarrmerReducer from './apps/farmer/FarmerSlice'
import VendorReducer from './apps/vendor/VendorSlice'
import ReportReducer from './apps/reports/ReportSlice'

const persistConfig = {
  key: 'root',
  storage,
};

// Combine all reducers into a single root reducer
const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  authReducer: AuthReducer,
  companyReducer: CompanyReducer,
  commonContextReducer: CommonContextReducer,
  subscriptionReducer: SubscriptionReducer,
  accessControlReducer: AccessControlReducer,
  farmerReducer: FarrmerReducer,
  vendorReducer: VendorReducer,
  reportReducer: ReportReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
