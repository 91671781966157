// export const baseUrl = 'http://localhost:8000/api';
// export const baseUrl = 'https://parikraman.echonsol.com/api';
// export const baseUrl = 'https://parikramanpro.echonsol.com/api';
// export const baseUrl = 'http://35.154.232.28:8000/api';
export const baseUrl = 'https://parikraman.com/api';
// Users
export const userLogin = '/users/login';
export const userTokenAuth = '/users/profile/auth';
export const getAllUsers = '/users/';
export const userRegistration = '/users/register';
export const deleteUser = '/users/delete/';
export const commonContext = '/common';
