import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { baseUrl, commonContext } from '../../../AppConst';

const initialState = {
  countries: [],
  states: [],
  districs: [],
  cities: [],
  banks: [],
  dropDownValues: [],
  months: [],
  loading: false,
  error: null,
};

export const commonContextSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload;
      state.loading = false;
      state.error = null;
    },
    setStates: (state, action) => {
      state.states = action.payload;
      state.loading = false;
      state.error = null;
    },
    setdistrics: (state, action) => {
      state.districs = action.payload;
      state.loading = false;
      state.error = null;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
      state.loading = false;
      state.error = null;
    },
    setBanks: (state, action) => {
      state.banks = action.payload;
      state.loading = false;
      state.error = null;
    },
    setHandlerList: (state, action) => {
      state.handlers = action.payload;
      state.loading = false;
      state.error = null;
    },
    setDropDownValues: (state, action) => {
      state.dropDownValues = action.payload;
      state.loading = false;
      state.error = null;
    },
    setMonth: (state, action) => {
      state.months = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  setCountries,
  setStates,
  setdistrics,
  setCities,
  setBanks,
  setHandlerList,
  setDropDownValues,
  setMonth,
  setLoading,
  setError,
} = commonContextSlice.actions;

export const fetchCountries = () => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${accessToken}` } };
    const response = await axios.get(`${baseUrl}${commonContext}/countries`, config);
    if (response.data.success === true) {
      dispatch(setCountries(response.data.data));
      return Promise.resolve(response.data.data);
    }
    dispatch(setError('Failed to fetch countries.'));
    return Promise.reject(new Error('Failed to fetch countries.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchStates = (id) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${accessToken}` } };
    const response = await axios.get(`${baseUrl}${commonContext}/States/${id}`, config);
    if (response.data.success === true) {
      dispatch(setStates(response.data.data));
      return Promise.resolve(response.data.data);
    }
    dispatch(setError('Failed to fetch states.'));
    return Promise.reject(new Error('Failed to fetch states.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchDistrics = (id) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${accessToken}` } };
    const response = await axios.get(`${baseUrl}${commonContext}/districs/${id}`, config);
    if (response.data.success === true) {
      dispatch(setdistrics(response.data.data));
      return Promise.resolve(response.data.data);
    }
    dispatch(setError('Failed to fetch states.'));
    return Promise.reject(new Error('Failed to fetch states.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchCities = (id) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${accessToken}` } };
    const response = await axios.get(`${baseUrl}${commonContext}/Cities/${id}`, config);
    if (response.data.success === true) {
      dispatch(setCities(response.data.data));
      return Promise.resolve(response.data.data);
    }
    dispatch(setError('Failed to fetch cities.'));
    return Promise.reject(new Error('Failed to fetch cities.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchBanks = () => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${accessToken}` } };
    const response = await axios.get(`${baseUrl}${commonContext}/banks`, config);
    if (response.data.success === true) {
      dispatch(setBanks(response.data.data));
      return Promise.resolve(response.data.data);
    }
    dispatch(setError('Failed to fetch banks.'));
    return Promise.reject(new Error('Failed to fetch banks.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchAllDropDownValues = (DropDownType) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${accessToken}` } };
    const data = { DropDownType };
    const response = await axios.post(
      `${baseUrl}${commonContext}/GetAllDropDownValue`,
      data,
      config,
    );
    if (response.data.success === true) {
      dispatch(setDropDownValues(response.data.data));
      return Promise.resolve(response.data.data);
    }
    dispatch(setError('Failed to fetch drop-down values.'));
    return Promise.reject(new Error('Failed to fetch drop-down values.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchDropDownValues = (DropDownType, isChild, SortOrder) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${accessToken}` } };
    const data = { DropDownType, isChild, SortOrder };
    const response = await axios.post(`${baseUrl}${commonContext}/GetDropDownValue`, data, config);
    if (response.data.success === true) {
      dispatch(setDropDownValues(response.data.data));
      return Promise.resolve(response.data.data);
    }
    dispatch(setError('Failed to fetch all drop-down values.'));
    return Promise.reject(new Error('Failed to fetch all drop-down values.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const addDropDownValue =
  (DropdownType, DropdownValue, ParentStringmapID) => async (dispatch) => {
    dispatch(setLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      // const user = await UserServices.loggedInUserData();
      const config = { headers: { Authorization: `Bearer ${accessToken}` } };
      const data = {
        DropdownType,
        DropdownValue,
        ParentStringmapID,
        // CurrentUserID: user._id,
      };
      const response = await axios.post(
        `${baseUrl}${commonContext}/AddDropDownValue`,
        data,
        config,
      );
      if (response.data.success === true) {
        return Promise.resolve(response.data.data[0]);
      }
      dispatch(setError('Failed to add drop-down value.'));
      return Promise.reject(new Error('Failed to add drop-down value.'));
    } catch (error) {
      dispatch(setError(error.message));
      return Promise.reject(error);
    }
  };

export const fetchHandlerList = () => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${accessToken}` } };
    const response = await axios.get(`${baseUrl}${commonContext}/GetHandlerList`, config);
    if (response.data.success === true) {
      dispatch(setHandlerList(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to Get drop-down value.'));
    return Promise.reject(new Error('Failed to Get drop-down value.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const addDeletionReq = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}${commonContext}/deleteReq`, data);
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(setError(error.message));
    throw error; // Re-throw the error to be handled by the caller
  }
};
export const convertDateFormat = (inputDate) => {
  const [day, month, year] = inputDate.split('-');
  const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  return formattedDate;
};

export const monthNames = [
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' },
];
// dispatch(setDropDownValues(monthNames));

export default commonContextSlice.reducer;
