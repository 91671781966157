import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { baseUrl } from '../../../AppConst';

const initialState = {
  farmers: [],
  Selectedfarmer: null,
  loading: false,
  error: null,
};

export const FarmerSlice = createSlice({
  name: 'farmerReducer',
  initialState,
  reducers: {
    getfarmers: (state, action) => {
      state.farmers = action.payload;
      state.loading = false;
      state.error = null;
    },
    Selectfarmer: (state, action) => {
      state.Selectedfarmer = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { getfarmers, Selectfarmer, setLoading, setError } = FarmerSlice.actions;

// Function to add or update a Farmer
export const addOrUpdateFarmer = (FarmerData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    if (FarmerData.FarmerId) {
      FarmerData.OperationType = 'U';
    } else {
      FarmerData.OperationType = 'I';
    }
    const response = await axios.post(`${baseUrl}/farmer/AddUpdate`, FarmerData, config);
    if (response.status === 200 || response.data.success === true) {
      // dispatch(getfarmers(response.data)); // Assuming you receive updated list of farmers after add/update
      return Promise.resolve(response.data); // Resolve the promise for successful add/update
    }
    dispatch(setError('Failed to add/update Farmer.'));
    return Promise.reject(new Error('Failed to add/update Farmer.')); // Reject the promise with an Error object for failed add/update
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

// Function to fetch Farmer by ID
export const fetchFarmerById = (FarmerId) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(`${baseUrl}/farmer/${FarmerId}`, config);
    if (response.status === 200) {
      dispatch(Selectfarmer(response.data)); // Assuming you receive single Farmer data here
      return Promise.resolve(response.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to fetch Farmer details.'));
    return Promise.reject(new Error('Failed to fetch Farmer details.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

// Function to fetch all farmers
export const fetchFarmers = () => async (dispatch) => {
  dispatch(setLoading());

  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await axios.get(`${baseUrl}/farmer/All`, config);
    if (response.status === 200) {
      console.log('ResponseData', response.data.data);
      //  dispatch(getfarmers(response.data)); // Assuming you receive list of farmers here
      return Promise.resolve(response.data.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to fetch farmers list.'));
    return Promise.reject(new Error('Failed to fetch farmers list.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};
// delte farmer by id
export const deletFarmerById = (FarmerId) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.delete(`${baseUrl}/farmer/delete/${FarmerId}`, config);
    if (response.status === 200) {
      toast.success(`Farmer Deleted Successfully`);
      dispatch(Selectfarmer(response.data)); // Assuming you receive single Farmer data here
      return Promise.resolve(response.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to delet Farmer details.'));
    return Promise.reject(new Error('Failed to delet Farmer details.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    toast.error(`Unable to Delete Farmer`);
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

export const fetchFamrersByFilter = (FarmerData) => async (dispatch) => {
  dispatch(setLoading());

  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await axios.post(`${baseUrl}/farmer/filter`, FarmerData, config);
    if (response.status === 200) {
      console.log('ResponseData', response.data.data);
      dispatch(getfarmers(response.data)); // Assuming you receive list of farmers here
      return Promise.resolve(response.data.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to fetch farmers list.'));
    return Promise.reject(new Error('Failed to fetch farmers list.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

export default FarmerSlice.reducer;
