import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
//  Routes, Route,
const ProtectedRoute = ({ component: Component, requiredAccess, moduleName, ...rest }) => {
  const accessControl = useSelector((state) => state.authReducer.moduleAccess);

  console.log('AccessControl', accessControl);
  // Function to check if the user has access to a module
  const hasAccess = (modulename) => {
    const module = accessControl.find((mod) => mod.ModuleName === modulename);
    return module ? module.ReadAccess === 1 : false;
  };

  // Check if the user has access to the specified module
  const isAllowed = hasAccess(moduleName);
  console.log('IsAllow', isAllowed);
  console.log('IsAllowComponent', Component);
  return isAllowed ? <Component {...rest} /> : <Navigate to="/auth/404" />;
  // return (
  //   <Routes>
  //     <Route {...rest} element={isAllowed ? <Component /> : <Navigate to="/auth/404" />} />
  //   </Routes>
  // );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  requiredAccess: PropTypes.number.isRequired,
  moduleName: PropTypes.string.isRequired,
};

export default ProtectedRoute;
