import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import * as jwtDecode from 'jwt-decode';
import { baseUrl, userLogin, userTokenAuth } from '../../../AppConst';

const initialState = {
  isAuthenticated: false,
  user: null,
  userDetail: [],
  profileInfo: [],
  moduleAccess: [], // Add moduleAccess to the initial state
  loading: false,
  error: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    initialiseAuth: (state, action) => {
      const { isAuthenticated, user } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.user = user;
      state.loading = false;
      state.error = null;
    },
    login: (state, action) => {
      const { user, modules } = action.payload; // Destructure modules from payload
      state.isAuthenticated = true;
      state.userDetail = action.payload;
      state.currentUserId = user.UserId;
      state.SubscriptionName = user.SubscriptionName;
      state.profileInfo = user;
      state.companyId = user.CompanyId;
      state.moduleAccess = modules; // Update moduleAccess
      state.loading = false;
      state.error = null;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.moduleAccess = []; // Clear moduleAccess on logout
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { initialiseAuth, login, logout, setLoading, setError } = authSlice.actions;

export const loginAsync = (email, password) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const response = await axios.post(baseUrl + userLogin, { email, password });
    if (response.status === 200 || response.data.success === true) {
      localStorage.setItem('UserInfo', JSON.stringify(response.data.data));
      if (response.data.data.user.role === 'SUPER_ADMIN') {
        localStorage.setItem('AdvanceAccess', 1);
      }
      const { accessToken, user, modules } = response.data.data;
      setSession(accessToken);
      dispatch(login({ user, modules })); // Pass modules to the login action
      localStorage.setItem('login', Date.now().toString());
      return { success: true, error: null, user };
    }
    dispatch(setError('Failed to login.'));
    return Promise.reject(new Error('Failed to login.'));
  } catch (error) {
    dispatch(setError('Failed to login.'));
    return { success: false, error: 'Invalid email or password' };
  }
};

export const logoutAsync = () => async (dispatch) => {
  setSession(null);
  dispatch(logout());
  localStorage.setItem('logout', Date.now().toString());
  return { success: true };
};

export const initialiseAuthAsync = () => async (dispatch) => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
      const response = await axios.post(`${baseUrl}/${userTokenAuth}`, { accessToken });
      const { user, modules } = response.data.data;
      dispatch(initialiseAuth({ isAuthenticated: true, user }));
      dispatch(login({ user, modules })); // Initialize modules as well
    } else {
      dispatch(initialiseAuth({ isAuthenticated: false, user: null }));
    }
  } catch (error) {
    console.error('Authentication initialization error:', error);
    dispatch(initialiseAuth({ isAuthenticated: false, user: null }));
  }
};

export default authSlice.reducer;
