import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../../AppConst';

const initialState = {
  vendors: [],
  Selectedvendor: null,
  loading: false,
  error: null,
};

export const VendorSlice = createSlice({
  name: 'vendorReducer',
  initialState,
  reducers: {
    getvendors: (state, action) => {
      state.vendors = action.payload;
      state.loading = false;
      state.error = null;
    },
    Selectvendor: (state, action) => {
      state.Selectedvendor = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { getvendors, Selectvendor, setLoading, setError } = VendorSlice.actions;

// Function to add or update a Vendor
export const addOrUpdateVendor = (VendorData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    if (VendorData.VendorId) {
      VendorData.OperationType = 'U';
    } else {
      VendorData.OperationType = 'I';
    }
    const response = await axios.post(`${baseUrl}/vendor/AddUpdate`, VendorData, config);
    if (response.status === 200 || response.data.success === true) {
      // dispatch(getvendors(response.data)); // Assuming you receive updated list of vendors after add/update
      return Promise.resolve(response.data); // Resolve the promise for successful add/update
    }
    dispatch(setError('Failed to add/update Vendor.'));
    return Promise.reject(new Error('Failed to add/update Vendor.')); // Reject the promise with an Error object for failed add/update
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

// Function to fetch Vendor by ID
export const fetchVendorById = (VendorId) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(`${baseUrl}/vendor/${VendorId}`, config);
    if (response.status === 200) {
      dispatch(Selectvendor(response.data)); // Assuming you receive single Vendor data here
      return Promise.resolve(response.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to fetch Vendor details.'));
    return Promise.reject(new Error('Failed to fetch Vendor details.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

// Function to fetch all vendors
export const fetchVendors = () => async (dispatch) => {
  dispatch(setLoading());

  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await axios.get(`${baseUrl}/vendor/All`, config);
    if (response.status === 200) {
      console.log('ResponseData', response.data.data);
      //  dispatch(getvendors(response.data)); // Assuming you receive list of vendors here
      return Promise.resolve(response.data.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to fetch vendors list.'));
    return Promise.reject(new Error('Failed to fetch vendors list.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

// delete  party
export const deleteVendorById = (VendorId) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.delete(`${baseUrl}/vendor/${VendorId}`, config);
    if (response.status === 200) {
      dispatch(Selectvendor(response.data)); // Assuming you receive single Vendor data here
      return Promise.resolve(response.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to delete Vendor details.'));
    return Promise.reject(new Error('Failed to delete Vendor details.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

export default VendorSlice.reducer;
