import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../../AppConst';

const initialState = {
  userGroups: [],
  selectedUserGroup: null,
  loading: false,
  error: null,
};

export const AccessControlSlice = createSlice({
  name: 'accessControlReducer',
  initialState,
  reducers: {
    getUserGroupModule: (state, action) => {
      state.userGroups = action.payload;
      state.loading = false;
      state.error = null;
    },
    selectUserGroup: (state, action) => {
      state.selectedUserGroup = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { getUserGroupModule, selectUserGroup, setLoading, setError } = AccessControlSlice.actions;

// Function to add or update a UserGroup
export const addOrUpdateUserGroupModuleById = (UserGroupModuleData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    // Iterate over each item in the UserGroupModuleData array
    const updatedData = UserGroupModuleData.map(item => {
      // Set OperationType based on whether ModuleID exists or not
      if (item.ModuleID) {
        return { ...item, OperationType: 'U' }; // Update operation
      } 
        return { ...item, OperationType: 'I' }; // Insert operation
    });

    // Send the updated data with OperationType to the server
    const response = await axios.post(`${baseUrl}/moduleMaster/AddUpdate`, updatedData, config);

    if (response.status === 200 || response.data.success === true) {
      dispatch(getUserGroupModule(response.data.data));
      return Promise.resolve(response.data.data); // Resolve the promise for successful add/update
    }
    
    dispatch(setError('Failed to add/update UserGroup.'));
    return Promise.reject(new Error('Failed to add/update UserGroup.')); // Reject the promise with an Error object for failed add/update
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};


// Function to fetch UserGroup by ID
export const fetchUserGroupModuleById = (ModuleId) => async (dispatch) => {
  dispatch(setLoading());
  selectUserGroup([])
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(`${baseUrl}/moduleMaster/${ModuleId}`, config);
    if (response.status === 200) {
      dispatch(selectUserGroup(response.data.data)); // Assuming you receive single UserGroup data here
      return Promise.resolve(response.data.data); // Resolve the promise for successful fetch
    }
    dispatch(setError('Failed to fetch UserGroup details.'));
    return Promise.reject(new Error('Failed to fetch UserGroup details.')); // Reject the promise with an Error object for failed fetch
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error); // Reject the promise with the caught error
  }
};

// Function to fetch all companies
// export const fetchSubscriptions = () => async (dispatch) => {
//   dispatch(setLoading());

//   try {
//     const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
//     const config = {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     };

//     const response = await axios.get(`${baseUrl}/subscription/All`, config);
//     if (response.status === 200) {
//        dispatch(getUserGroupModule(response.data.data)); // Assuming you receive list of companies here
//       return Promise.resolve(response.data.data); // Resolve the promise for successful fetch
//     }
//     dispatch(setError('Failed to fetch companies list.'));
//     return Promise.reject(new Error('Failed to fetch companies list.')); // Reject the promise with an Error object for failed fetch
//   } catch (error) {
//     dispatch(setError(error.message));
//     return Promise.reject(error); // Reject the promise with the caught error
//   }
// };

export default AccessControlSlice.reducer;
